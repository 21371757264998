<template>
  <v-container>
    <v-skeleton-loader
      v-if="!settings_loaded"
      type="article,article"
    ></v-skeleton-loader>
    <v-form ref="form" v-if="settings_loaded">
      <v-card>
        <v-card-title>شهور الشهادة</v-card-title>
        <v-card-text>
          <v-row class="align-center" v-for="(item, i) in settings" :key="i">
            <v-col cols="5" sm="4">
              <v-text-field
                :rules="months_rules"
                type="number"
                min="1"
                v-model="item.months"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  الشهور
                </template>
              </v-text-field>
            </v-col>
            <!--/ col -->
            <v-col cols="5" sm="4">
              <v-text-field
                type="number"
                min="1"
                :rules="percent"
                v-model="item.percent"
              >
                <template slot="label">
                  النسبة
                  <strong class="red--text">*</strong>
                </template>
              </v-text-field>
            </v-col>
            <!--/ col -->
            <v-col cols="1">
              <v-btn
                @click="get_delete_id(item, i)"
                fab
                x-small
                elevation="1"
                color="error"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </v-col>
            <!--/ col -->
          </v-row>
          <!--/ row -->
        </v-card-text>
        <!--/ card text -->
        <v-divider></v-divider>
        <v-card-actions class="pa-5">
          <v-btn
            outlined
            @click="
              settings.push({
                months: null,
                percent: null,
              })
            "
            color="primary"
            small
          >
            <v-icon>add</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="save_loading"
            :loading="save_loading"
            @click="validate"
            color="primary"
          >
            حفظ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>



    <v-snackbar width="auto" right v-model="snackbar">
        تم حفظ اعدادت الشهادات
      <template v-slot:action="{ attrs }">
        <v-icon
          color="success"
          v-bind="attrs"
          >done</v-icon
        >
      </template>
    </v-snackbar>
    
    <v-dialog max-width="500" v-model="dialog.prop">
      <v-card>
        <v-card-title>تأكيد الحذف</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <h2 class="text-center mb-3 font-weight-medium">
            هل تريد حذف النسبة
          </h2>
          <div class="text-center">
            <v-chip class="me-2"> النسبة : {{ dialog.percent }}</v-chip>
            <v-chip> الشهور : {{ dialog.months }} </v-chip>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            :disabled="dialog_loading"
            :loading="dialog_loading"
            @click="delete_setting"
            color="error"
          >
            حذف
          </v-btn>
          <v-btn @click="dialog.prop = false" color="success" text>
            الغاء
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--/ dialog -->
  </v-container>
</template>

<script>
export default {
  name: "Certificates_settings",
  data() {
    return {
      settings_loaded:false,
      snackbar:false,
      save_loading: false,
      dialog_loading: false,
      months_rules: [(v) => !!v || "برجاء ادخال الشهور"],
      percent: [(v) => !!v || "برجاء ادخال النسبة"],
      dialog: {
        prop: false,
        percent: "",
        months: "",
        id: null,
        index: null,
      },
      settings: [],
    };
  },
  methods: {
    get_settings() {
      this.$store
        .dispatch("public__request", {
          config: {
            method: "get",
            url: "certificates_settings",
          },
        })
        .then((res) => {
          this.settings = res.data.data.data;
          this.settings_loaded = true;
        }).catch(err=> {
          // console.log(err.response)
        })
    },
    get_delete_id(item, index) {
      if (item.id) {
        this.dialog.percent = item.percent;
        this.dialog.months = item.months;
        this.dialog.id = item.id;
        this.dialog.index = index;
        this.dialog.prop = true;
      } else {
        this.settings.splice(index, 1);
      }
    },
    delete_setting() {
      if (this.dialog.id) {
        this.dialog_loading = true;
        this.$store
          .dispatch("public__request", {
            config: {
              method: "delete",
              url: `certificates_settings/${this.dialog.id}`,
            },
          })
          .then((res) => {
            this.get_settings();
            this.dialog_loading = false;
            this.dialog = {
              prop: false,
              percent: "",
              months: "",
              id: null,
              index: null,
            };
          });
      } else {
        this.settings.splice(this.dialog.index, 1);
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.save_loading = true;
        this.$store
          .dispatch("public__request", {
            config: {
              method: "post",
              url: "certificates_settings",
            },
            data: this.settings,
          })
          .then((res) => {
            this.save_loading = false;
            this.snackbar = true;
            this.get_settings();
          });
      }
    },
  },
  mounted() {
    this.get_settings();
  },
};
</script>
